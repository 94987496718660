import * as React from "react"
import { graphql, Link } from "gatsby"
// import slugify from "@sindresorhus/slugify"
import debounce from "debounce" 
import { CgChevronRight, CgChevronLeft } from "react-icons/cg"
import Layout from "../components/layout"
import CrossIcon from "../icons/cross"
import SearchIcon from "../icons/search"
import { getValuesFromQueryString, useProductSearch } from "../utils/hooks"
// import { getCurrencySymbol } from "../utils/format-price"
import SearchCard from "../components/search-card"
// import { Spinner } from "../components/progress"
import { SearchProvider } from "../context/search-provider"
import {
  main,
  search,
  searchIcon,
  results,
  productList as productListStyle,
  productListItem,
  pagination,
  paginationButton,
  splash,
  clearSearch,
  searchForm,
  modalOpen,
} from "./search-page.module.css"
import { Seo } from "../components/seo"

export const query = graphql`
  query {
    meta: allShopifyProduct {
      productTypes: distinct(field: productType)
      tags: distinct(field: tags)
      vendors: distinct(field: vendor)
    }
    products: allShopifyProduct(
      limit: 24,
      filter: {handle: {ne: "gift-wrap"}, collections: {elemMatch: {title: {in: ["Merch", "Rare", "Fiction", "Nonfiction", "Art", "Pickles"]}}}}
      sort: { fields: title }) {
      edges {
        node {
          title
          vendor
          productType
          handle
          priceRangeV2 {
            minVariantPrice {
              currencyCode
              amount
            }
            maxVariantPrice {
              currencyCode
              amount
            }
          }
          id
          images {
            gatsbyImageData(width: 910)            
          }
        }
      }
    }
  }
`

function SearchPage({
  data: {
    meta: { productTypes, vendors, tags },
    products,
  },
  location,
}) {
  // These default values come from the page query string
  const queryParams = getValuesFromQueryString(location.search)
  const [filters, setFilters] = React.useState(queryParams)
  const [sortKey, setSortKey] = React.useState(queryParams.sortKey)
  // We clear the hash when searching, we want to make sure the next page will be fetched due the #more hash.
  const shouldLoadNextPage = React.useRef(false)
  // This modal is only used on mobile
  // const [showModal, setShowModal] = React.useState(false)

  const {
    data,
    isFetching,
    filterCount,
    hasNextPage,
    hasPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
  } = useProductSearch(
    filters,
    {
      allProductTypes: productTypes,
      allVendors: vendors,
      allTags: tags,
    },
    sortKey,
    false,
    24 // Products per page
  )

  // If we're using the default filters, use the products from the Gatsby data layer.
  // Otherwise, use the data from search.
  const isDefault = !data
  // const productList = (isDefault ? products.edges : data?.products?.edges) ?? []
  const productList = filters.term ? data?.products?.edges : []

  // Automatically load the next page if "#more" is in the URL
  React.useEffect(() => {
    if (location.hash === "#more") {
      // save state so we can fetch it when the first page got fetched to retrieve the cursor
      shouldLoadNextPage.current = true
    }

    if (shouldLoadNextPage.current) {
      if (hasNextPage) {
        fetchNextPage()
      }

      shouldLoadNextPage.current = false
    }
  }, [location.hash])

  return (
    <Layout containerStyle={"noLanding"}>
      <Seo title="Search" />
      <div className={main}>
        <div className={search} aria-hidden={modalOpen}>
          <SearchBar defaultTerm={filters.term} setFilters={setFilters} />
        </div>
      
        <section
          className={results}
          aria-busy={isFetching}
          aria-hidden={modalOpen}
        >
          {(isFetching && filters.term) && (
            <p className={splash}>
              {/* <Spinner aria-valuetext="Searching" />  */}
              Searching
              {filters.term ? ` for "${filters.term}"…` : `…`}
            </p>
          )}
          {(!isFetching && filters.term && productList.length===0) && (
            <div className={splash}>
              <h2>No results...</h2>
              <p>Would you like to <Link to="/requests"><span>request</span></Link> a book?</p>
            </div>
          )}
          <ul className={productListStyle}>
            {!isFetching &&
              productList.filter(({node}) => !node.title.toLowerCase().includes('in store') && !node.title.toLowerCase().includes('outside order') && !node.title.toLowerCase().includes('gift wrap')).map(({ node }, index) => (
                <li className={productListItem} key={node.id}>
                  <SearchCard result={node} />
                </li>
              ))}
          </ul>
          {hasPreviousPage || hasNextPage ? (
            <Pagination
              previousPage={fetchPreviousPage}
              hasPreviousPage={hasPreviousPage}
              nextPage={fetchNextPage}
              hasNextPage={hasNextPage}
            />
          ) : undefined}
        </section>
      </div>
    </Layout>
  )
}

function SearchBar({ defaultTerm, setFilters }) {
  const [term, setTerm] = React.useState(defaultTerm)
  const debouncedSetFilters = React.useCallback(
    debounce((value) => {
      setFilters((filters) => ({ ...filters, term: value }))
    }, 200),
    [setFilters]
  )

  return (
    <form onSubmit={(e) => e.preventDefault()} className={searchForm}>
      <SearchIcon aria-hidden className={searchIcon} />
      <input
        type="text"
        value={term}
        onChange={(e) => {
          setTerm(e.target.value)
          debouncedSetFilters(e.target.value)
        }}
        placeholder="Search..."
      />
      {term ? (
        <button
          className={clearSearch}
          type="reset"
          onClick={() => {
            setTerm("")
            setFilters((filters) => ({ ...filters, term: "" }))
          }}
          aria-label="Clear search query"
        >
          <CrossIcon />
        </button>
      ) : undefined}
    </form>
  )
}
/**
 * Shopify only supports next & previous navigation
 */
function Pagination({ previousPage, hasPreviousPage, nextPage, hasNextPage }) {
  return (
    <nav className={pagination}>
      <button
        className={paginationButton}
        disabled={!hasPreviousPage}
        onClick={previousPage}
        aria-label="Previous page"
      >
        <CgChevronLeft />
      </button>
      <button
        className={paginationButton}
        disabled={!hasNextPage}
        onClick={nextPage}
        aria-label="Next page"
      >
        <CgChevronRight />
      </button>
    </nav>
  )
}

export default function SearchPageTemplate(props) {
  return (
    <SearchProvider>
      <SearchPage {...props} />
    </SearchProvider>
  )
}