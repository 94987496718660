import * as React from "react"
import { Link } from "gatsby"
import { formatPrice } from "../utils/format-price"

import {
  productCardStyle,
  productHeadingStyle,
  productDetailsStyle,
  productSubtitleStyle,
  productPrice,
  imageContainer,
  fakeLink,
} from "./search-card.module.css"


const SearchCard = ({ result }) => {
  const {
    title,
    handle,
    priceRangeV2,
    images,
  } = result

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    priceRangeV2.minVariantPrice.amount
  )

  return (
    <Link to={`/products/${handle}`} className={productCardStyle}>
      <div className={imageContainer}>
        {images.edges.length !== 0 && <img src={images.edges[0].node.originalSrc} alt="" />}
      </div>
      <div className={productDetailsStyle}>
          <h2 as="h2" className={productHeadingStyle}>{title}</h2>        
          <div className={productSubtitleStyle}>Sweet Pickle Books
            {/* {
            metafields.length !== 0 ? 
              metafields.find(field=>field.key="subtitle").value 
              : 
              "Sweet Pickle Books"
            } */}
          </div>
          <p className={fakeLink}>More info</p>
        </div>
      <div className={productPrice}>{price}</div>

    </Link>
  )
}

export default SearchCard